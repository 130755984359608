<template>
  <div class="content-container">
    <div class="human-ai-icon">
      <HumanAiIcon></HumanAiIcon>
    </div>
    <div class="buttons-container">
      <div class="left button">
        <SingleButton @click="showReferenceImages()" background_color="black" text_color="white" text="Referenzbilder"></SingleButton>
      </div>
      <div class="right button">
        <SingleButton @click="confirmBeadIO()" background_color="black" text_color="white" text="iO Kleberaupe bestätigen"></SingleButton>
      </div>
    </div>
  </div>
  <BackButton
    text="Zurück"
    color="white"
    image_name="Zurueck"
    target_name="AIBeadNIO"
  ></BackButton>
</template>

<script>
import HumanAiIcon from '../components/HumanAiIcon.vue';
import SingleButton from '../components/SingleButton.vue';
import BackButton from '../components/BackButton.vue';
import FinishButton from '../components/FinishButton.vue';

export default {
    name: 'OverviewAIBeadNIOReject',
    created() {
    this.$emit("set-title", "Übersicht")
    this.$emit("set-titlecontainercolor", "black")
    document.body.style.backgroundColor = '#FF6200'
  },
  components: {
    HumanAiIcon,
    BackButton,
    FinishButton,
    SingleButton
  },
  methods: {
    showReferenceImages() {
      this.$router.push({
        name: 'ReferenceImagesNIO',
      })
    },
    confirmBeadIO() {
      this.$router.push({
        name: 'ErrorDocumentation',
      })
    }
  },
  emits:["set-title", "set-titlecontainercolor"]

}
</script>

<style>
  .buttons-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .button{
    width: 40%;
  }

</style>
<template>

    <div class="list_wrapper">
        <div class="column">
            <div class="cell head left">
                {{ leftTitle }}
                <!--<img src="../assets/icons/Bearbeiten.svg" class="edit_img" alt="Bearbeiten"/>-->
            </div>
            <div class="cell" v-for="entry in leftEntries">
                {{ entry }}
            </div>
        </div>
        <div class="column">
            <div class="cell head right">
                {{ rightTitle }}
                <!--<img src="../assets/icons/Bearbeiten.svg" class="edit_img" alt="Bearbeiten"/>-->
            </div>
            <div class="cell" v-for="entry in rightEntries">
                {{ entry }}
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'DoubleList',
    props: {
        leftTitle: String,
        rightTitle: String,
        leftEntries: {
            default: []
        },
        rightEntries: {
            default: []
        }
    },
    }
</script>

<style scoped>

.list_wrapper{
    width: 100%;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 2%;
    margin-top: 2.5%;
}

.cell{
    background-color: white;
    padding: 2%;
    padding-left: 5%;
    margin-top: 2.5%;
    border-radius: 5px;
    font-weight: bold;
    font-size: 13px;
}

.edit_img{
    width: 9%;
    float: right;
    filter: invert(100%);
}

.left {
    background-color: #FF7C2B;
}

.right {
    background-color: #04a404;
}

.head {
    color: white;
    font-size: 10.5px;
    padding: 4%;
    padding-left: 5%;
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
}


</style>
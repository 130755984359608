<template>
  <div class="button-container">
    <div class="wrapper" style="margin-right:2.5%">
      <ImageButton
        @click.stop="this.placeholder == '0' ? $router.push({name: 'BeadIO'}) : '' "
        :image=IO_Image
        >
      </ImageButton>
      <span class="caption">io</span>
    </div>
    <div class="wrapper" style="margin-left:2.5%">
      <ImageButton
        @click.stop="this.placeholder == '0' ? $router.push({name: 'BeadNIO'}) : '' "
        :image=NIO_Image
      ></ImageButton>
      <span class="caption">nio</span>
    </div>
  </div>
</template>

<script>

import ImageButton from "../components/ImageButton"
import IO_Image from "../assets/img/IO_image.png"
import NIO_Image from "../assets/img/NIO_image.png"

export default {
  name: 'AINoClassification',
  props: {
    placeholder: String
  },
  components: {
    ImageButton
  },
  beforeUnmount() {
    this.socket.close();
  },
  created() {
    this.$emit("set-title", "Kann nicht beurteilt werden")
    this.$emit("set-titlecontainercolor", "#e5c933")
    document.body.style.backgroundColor = '#DEBC00'
  },
  setup() {
    return {
      IO_Image, NIO_Image
    };
  },
  emits:["set-title", "set-titlecontainercolor", "request-image"]
}
</script>

<style scoped>
img {
   height: 100%;
   width: 100%;
   object-fit: contain;
   margin-top: 2.5%;
}
.imagebutton{
    border-radius: 15px;
}
.wrapper{
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 47.5%;
  margin-top: 12.5%;
}

.button-container{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10%;
}
.caption{
  margin-top: 2.5%;
  display: block;
  font-size: 26px;
  font-weight: bold;
}
</style>

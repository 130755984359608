<template>
        <button 
            class="multibutton"
            :style="{
                'background-color': isActive ? description_color : 'white', 
                'grid-column': last ? 'span 2' : 'span 1', 
                'margin-left': last ? '26.25%' : '0%',
                'margin-top': last ? '2.5%' : '5%',
                'margin-right': last ? '26.25%' : '0%'
                }"
            @click.stop="toggle()">
            <h2 class="multibtn_title" :style="{ color: isActive ? 'white' : 'black'}">{{title}}</h2>
            <h3 class="multibtn_descriptor" :style="{ color: isActive ? 'white' : description_color}">{{descriptor}}</h3> 
        </button>
</template>

<script>
    export default {
        name: 'MultiButton',
        props : {
            description_color: String,
            title: String,
            descriptor: String,
            id: Number,
            last: Boolean,
            initial_active: Boolean
        }, 
        data() {
            return {
                isActive: false
            }
        },    
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                this.$emit('toggle-button', this.id);
            }
        },
        created() {
            this.isActive = this.initial_active;
        },
        emits:["toggle-button"]
    }
</script>
  
<style scoped>
    .multibutton{
        border: none;
        margin-top: 5%;
        border-radius: 7.5px;
        padding: 2.5%;
    }
    .multibtn_title{
        text-align: left;
        padding: 2.5%;
        font-size: 16px;
        padding-bottom: 0;
    }
    .multibtn_descriptor{
        text-align: left;
        font-size: 10px;
        padding: 2.5%;
        padding-bottom: 1.25%;
    }
</style>
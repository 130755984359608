<template>
  <div class="content-container">
    <div class="human-ai-icon">
      <HumanAiIcon></HumanAiIcon>
    </div>
    <div class="big text"> Bitte Fehlerbehebung an Anlage durchführen</div>
    <div class="small text">Danke für Deine Hilfe</div>
    <div class="symbol">
      <img class="engineer-img" src="../assets/icons/Fehlerbehebung.svg"/>
    </div>
  </div>
</template>

<script>
import HumanAiIcon from '../components/HumanAiIcon.vue';
import BackButton from '../components/BackButton.vue';
import FinishButton from '../components/FinishButton.vue';

export default {
    name: 'OverviewAIBeadNIO',
    created() {
    this.$emit("set-title", "Übersicht")
    this.$emit("set-titlecontainercolor", "black")
    document.body.style.backgroundColor = '#FF6200'
  },
  components: {
    HumanAiIcon,
    BackButton,
    FinishButton
  },
  emits:["set-title", "set-titlecontainercolor"]

}
</script>

<style>
  .content-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .symbol{
    height: 20%;
  }

</style>
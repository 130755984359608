<template>
    <div class="wrapper_multi">
        <MultiButton 
            v-for="(obj,index) in titles"
            :id=obj.id
            :title=obj.title
            :descriptor=obj.descriptor
            :description_color=description_color
            @toggle-button="toggle_button"
            :last="(index == titles.length - 1) && titles.length % 2 == 1"
            :initial_active="activeButtons.has(obj.id)"
        />
    </div>
</template>

<script>

    import MultiButton from './MultiButton.vue';

    export default {
        name: 'MultiButtons',
        components : {
            MultiButton,
        },
        props : {
            description_color: String,
            titles: Array,
            initial: Set
        },        
        methods: {
            toggle_button(id) {
                this.activeButtons.has(id) ? this.activeButtons.delete(id) : this.activeButtons.add(id);
                this.$emit('toggle-button', this.activeButtons);
            },
        },
        data() {
            return {
                activeButtons: new Set([])
            }
        },
        created() {
            this.activeButtons = this.initial;
            this.$emit('toggle-button', this.activeButtons);
        },
        emits:["toggle-button"]
    }
</script>
  
<style scoped>
    .wrapper_multi{
        width: 100%;
        display: grid;
        grid-template-columns: 47.5% 47.5%;
        column-gap: 5%;
        margin-top: 2.5%;
    }
</style>
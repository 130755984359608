<template>
 <!-- <Carousel :items-to-show="1.5" :wrap-around="true">
    <Slide>
        <img src="../assets/img/sample_kleberaupe.png"/>
        <div class="error-list">
            <div class="error">kein Kleber vorhanden</div>
            <div class="error">Lücke</div>
        </div>
    </Slide>
    <Slide>
        <img src="../assets/img/sample_kleberaupe.png"/>
        <div class="error-list">
            <div class="error">kein Kleber vorhanden</div>
            <div class="error">Falsche Position</div>
            <div class="error">Lücke</div>
        </div>
    </Slide>

    <template #addons>
        <Navigation />
        <Pagination />
    </template>
 </Carousel> -->


<Carousel :items-to-show="1.5" :wrap-around="true">
    <!-- <Slide v-for="slide in 4" :key="slide">
        <div class="slide-content">
            <div class="reference-img">
                <img src="../assets/img/sample_kleberaupe.png"/> 
            </div>
            <div class="error-list">
                <div class="error">kein Kleber vorhanden</div>
                <div class="error">Falsche Position</div>
                <div class="error">Lücke</div>
            </div>     

        </div>
    </Slide> -->
    <Slide v-for="(shownError, index) in this.errorData" :key="index">
        <div v-bind:class="getBackgroundClass()">
            <div class="reference-img">
                <img :src="require(`@/${this.imgFolderPath}${shownError.fileName}`)"/>
            </div>
            <div class="error-list" v-for="(errorString, errorStringIndex) in shownError.errors" :key="errorStringIndex">
                <div class="error">{{errorString}}</div>
            </div>     

        </div>
    </Slide>

    <template #addons>
        <Navigation />
        <Pagination />
    </template>
</Carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'ReferenceImageCarousel',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            imgFolderPath: 'assets/img/',
            errorData: [
                {"fileName": "sample_kleberaupe.png", errors: ["kein Kleber vorhanden", "Falsche Position", "Lücke"]},
                {"fileName": "sample_kleberaupe_1.png", errors: ["Error 1", "Error 2"]},
                {"fileName": "sample_kleberaupe_2.png", errors: ["Error 3", "Error 4"]},
                {"fileName": "sample_kleberaupe_3.png", errors: ["Error 5", "Error 6"]}
        ],
        }
    },
    methods: {
        getBackgroundClass () {
            if(this.$route.path === '/ReferenzbilderIO'){
                return 'slide-content-io';
            } else {
                return 'slide-content-nio';
            }
        },
        getRoute () {
                return this.$route.path;
        },
    }
}
</script>

<style scoped>
    .slide-content-nio {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #FF7C2B;
        width: 95%;
    }

    .slide-content-io {
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: #00a300;
        width: 95%;
    }
    
    img {
        width: 100%;
    }

    .error-list{
        display: flex;
        flex-wrap: wrap;
    }

    .error{
        border-color: white;
        border-style:solid;
        border-width: 2px;
        color: white;
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
</style>
<template>
    <img class="bead_img" :src=imageUrl alt="Bild Kleberaupe">
</template>

<script>

export default {
  name: 'BeadImage',
  props: {
    imageUrl: String
  }
}
</script>

<style scoped>
.bead_img {
   height: 100%;
   width: 100%;
   object-fit: contain;
   margin-top: 2.5%;
}
</style>
import { http, HttpResponse } from "msw";

export const handlers = [
  // Intercept "GET http://localhost:8080/cam/getImage" requests...
  http.get('/cam/getImage', async () => {
    var imgs = ['sample_kleberaupe_1.png', 'sample_kleberaupe_3.png', 'sample_kleberaupe_3.png',
      'sample_kleberaupe_3.png', 'sample_kleberaupe_3.png', 
      'viel-kleber.png', 'wenig-kleber.png', 'luecke.png', 'kein-kleber.png'
    ];
    var img = imgs[Math.floor(Math.random()*imgs.length)];
    const buffer = await fetch(img).then(
      (response) => response.arrayBuffer() 
    )
    return HttpResponse.arrayBuffer(buffer, {
      headers: {
        'Content-Type': 'image/png',
        'X-system_id': 'test',
        'X-part_id': 'test',
        'X-placeholder': '0',
      }
    })
  }),
  http.post('api/fuegen/*/*/kleberaupe*', () => {
    return new HttpResponse(null, {
      status: 299,
      statusText: 'Test environment detected. Request accepted by mock server.',
    })
  })
]
<template>
      <div style="margin-top: 5%">
        <TextField 
            v-for="id in this.chosenErrors"
              :text="this.titles.find((x) => x.id == id).title"
        />
      </div>
      <BackButton
        text="Zurück"
        color="white"
        image_name="Zurueck"
        target_name="OverviewDocumentation"
        :target_parameters="{ chosenErrors: this.chosenErrors }"
      ></BackButton>
      <FinishButton
        text="Korrekt"
        :visible=true
        color="white"
        image_name="io"
        target_name="PromptTroubleshooting"
        :target_parameters="{ chosenErrors: this.chosenErrors }"
      ></FinishButton>
</template>
<script>

import BackButton from "../components/BackButton.vue"
import FinishButton from "../components/FinishButton.vue"
import TextField from "../components/TextField.vue"
import Titles from "../assets/json/Beschreibungen_FehlerDokumentation.json"

export default {
    name: 'OverviewChosenErrors',
    inheritAttrs: false,
    components: {
        BackButton,
        FinishButton,
        TextField
    },
    created() {
        this.$emit("set-title", "Übersicht")
        this.$emit("set-titlecontainercolor", "#FF7C2B")
        document.body.style.backgroundColor = '#DBDBDB'
        this.chosenErrors = this.$route.query.chosenErrors
    },
    data() {
        return {
          chosenErrors: new Set([]),
          titles: Titles
        }
    },
    emits:["set-title", "set-titlecontainercolor"]
    }
</script>
<template>
    <div class="field">
        <h1 class="text">
            {{ text }}
        </h1>
    </div>
</template>
    
<script>
  
    export default {
        name: 'TextField',
        props : {
            text: String
        }
    }
</script>
  
<style scoped>
.field{
    background-color: white;
    margin-top: 2%;
    padding: 1%;
    padding-left: 2%;
    border-radius: 8px;
}
.text{
    font-size: 14px;
}
</style>
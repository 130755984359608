<template>
    <MultiButtons
        description_color="#3100FF"
        :titles = titles
    />
    <BackButton
        text="Zurück"
        @click.stop="$router.push({
            name: 'ErrorDocumentation'
        })"
        color="white"
        image_name="Zurueck"
    ></BackButton>
</template>

<script>

import MultiButtons from "../components/MultiButtons.vue"
import BackButton from "../components/BackButton.vue"
import Titles from "../assets/json/Beschreibungen_FehlerBehebung.json"

export default {
    name: 'ErrorCorrection',
    props: {
    },
    components: {
        MultiButtons,
        BackButton
    },
    methods: {
    },
    created() {
        this.$emit("set-title", "Behebung Fehler")
        this.$emit("set-titlecontainercolor", "#3100FF")
        document.body.style.backgroundColor = '#DBDBDB'
    },
    data() {
        return {
            titles: Titles
        }
    },
    emits:["set-title", "set-titlecontainercolor"]
    }
</script>
<template>
    <MultiButtons
        description_color="#FF7C2B"
        :titles = titles
        @toggle-button="toggle_button"
        :initial = this.activeButtons
    />
    <BackButton
      text="Zurück"
      color="white"
      image_name="Zurueck"
      target_name="ErrorDocumentation"
    ></BackButton>
    <FinishButton
      text="Fertig"
      :visible=ready
      color="white"
      image_name="io"
      target_name="OverviewChosenErrors"
      :target_parameters="{ chosenErrors: Array.from(this.activeButtons), chosenMeasures: [] }"
      :navigate_default="navigate_default"
      alternate_target_name="OverviewAll"
    ></FinishButton>
</template>

<script>

import MultiButtons from "../components/MultiButtons.vue"
import BackButton from "../components/BackButton.vue"
import Titles from "../assets/json/Beschreibungen_FehlerDokumentation.json"
import FinishButton from "../components/FinishButton.vue"

export default {
    name: 'OverviewDocumentation',
    components: {
        MultiButtons,
        BackButton,
        FinishButton
    },
    methods: {
      toggle_button(activeButtons){
        this.ready = (activeButtons.size != 0);
        this.activeButtons = activeButtons;
        this.navigate_default = !this.activeButtons.has(4);
      }
    },
    created() {
        this.$emit("set-title", "Übersicht Dokumentation Fehler")
        this.$emit("set-titlecontainercolor", "#FF7C2B")
        document.body.style.backgroundColor = '#DBDBDB'
        if(this.$route.query.chosenErrors != null){
          let chosenErrors = this.$route.query.chosenErrors.map(Number)
          this.activeButtons = new Set(chosenErrors);
        }
    },
    data() {
        return {
            titles: Titles,
            ready: false,
            activeButtons: new Set([]),
            navigate_default: true
        }
    },
    emits:["set-title", "set-titlecontainercolor"]
    }
</script>
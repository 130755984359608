<template>

  <div class="content-container">
    <div class="human-ai-icon">
      <HumanAiIcon></HumanAiIcon>
    </div>
    <div class="buttons-container">
      <div class="left button">
        <SingleButton @click="showReferenceImages()" background_color="black" text_color="white" text="Referenzbilder"></SingleButton>
      </div>
      <div class="right button">
        <SingleButton @click="documentError()" background_color="black" text_color="white" text="Dokumentation Fehler"></SingleButton>
      </div>
    </div>
  </div>
  <BackButton
    text="Zurück"
    color="white"
    image_name="Zurueck"
    target_name="AIBeadIO"
  ></BackButton>

</template>

<script>
import HumanAiIcon from '../components/HumanAiIcon.vue';
import SingleButton from '../components/SingleButton.vue';
import BackButton from '../components/BackButton.vue';
import FinishButton from '../components/FinishButton.vue';

export default {
    name: 'OverviewAIBeadIOReject',
    created() {
    this.$emit("set-title", "Übersicht")
    this.$emit("set-titlecontainercolor", "black")
    document.body.style.backgroundColor = '#00A300'
    //document.body.style.backgroundImage = "linear-gradient(90deg, #00a300 50%, #ff6200 50%)" //'#00A300'
  },
  //created() {
  //  if(this.$route.path === '/UebersichtKIIOAbgelehnt'){
  //    document.body.style.backgroundImage = "linear-gradient(90deg, #00a300 50%, #ff6200 50%)" //'#00A300'
  //  }
  //},
  components: {
    HumanAiIcon,
    BackButton,
    FinishButton,
    SingleButton
  },
  computed: {
      isOverviewDocumentation() {
        return this.$route.path === '/UebersichtKIIOAbgelehnt';
      }
  },
  methods: {
    showReferenceImages() {
      this.$router.push({
        name: 'ReferenceImagesIO',
      })
    },
    documentError() {
      this.$router.push({
        name: 'OverviewDocumentation',
      })
    }
  },
  emits:["set-title", "set-titlecontainercolor"]

}
</script>

<style>
  .buttons-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  .button{
    width: 40%;
  }

</style>
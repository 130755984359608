<template>
  <header>
    <div class="title-container" :style="{ background: titleContainerColor }">
      <h1 class="title">{{ title }}</h1>
    </div>
    
  </header>
</template>

<script>

export default {
  name: 'Header',
  props: {
    title: String,
    titleContainerColor: String
  },
  components: {
    
  },
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.title {
  text-align: left;
  letter-spacing: 0.21px;
  color: #FFFFFF;
  opacity: 1;
  font-family: 'Arial';
  font-size: 28px;
  font-weight: 1200;
}
.title-container {
  background-color:black;
  width: 1900px;
  padding-left: 20px;
  padding-top: 50px;
  padding-bottom: 15px;
  border-radius: 0px 0px 15px 15px;
}
</style>

<template>
    <div class="wrapper_finish"
        :style="visible ? { display: 'inline-flex' } : { display: 'none' }"
    >
        <h3 class="descriptor">{{text}}</h3>
        <button :style="{ background: color }" @click.stop="navigate(target_name, target_parameters, alternate_target_name)">
            <img :src="require(`../assets/icons/${image_name}.svg`)" :style="{ filter: image_filter }"/>
        </button>
    </div>
</template>
    
<script>
 
    export default {
        name: 'FinishButton',
        props : {
            text: String,
            visible: Boolean,
            color: String,
            image_name: String,
            image_filter: {
                default: "",
                type: String
            },
            target_name: String,
            target_parameters : {
                default: {}
            },
            navigate_default : {
                default: true,
                type: Boolean
            },
            alternate_target_name: {
                default: "",
                type: String
            } 
        },
        methods: {
            navigate(target_name, target_parameters, alternate_target_name){
                if(this.navigate_default){
                    this.$router.push({
                        name: target_name, 
                        query: target_parameters
                    });
                }else{
                    this.$router.push({
                        name: alternate_target_name, 
                        query: target_parameters
                    });
                }
            }
        }
    }
</script>
  
<style scoped>
    button{
        display:block;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        border: none;
        background-color: white;
        margin-left: 25px;
    }
    img{
        width: 35%;
        height: 30%
    }
    .descriptor{
        font-size: 12px;
        color: black;
    }
    .wrapper_finish{
        display: inline-flex;
        width: 50%;
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        bottom: 20px;
        right: 40px;
    }
</style>
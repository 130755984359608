<template>
    <div class="container">
    <ReferenceImageCarousel class="img-list"></ReferenceImageCarousel>
    <BackButton
      text="Zurück"
      color="white"
      image_name="Zurueck"
      target_name="OverviewAIBeadIOReject"
    ></BackButton>
    </div>
</template>
  
<script> 
import ReferenceImageCarousel from '../components/ReferenceImageCarousel.vue'
import BackButton from '../components/BackButton.vue'

export default {
    name: 'ReferenceImagesIO',
    created() {
        this.$emit("set-title", "Übersicht Referenzbilder IO")
        this.$emit("set-titlecontainercolor", "black")
        document.body.style.backgroundColor = '#E7E8EA'
    },
    components: {
        ReferenceImageCarousel,
        BackButton
    },
    emits:["set-title", "set-titlecontainercolor"]

}
</script>
  
<style>
.img-list{
    margin-top: 20px;
    margin-bottom: 100px;
}
</style>
export const addEntry = async (api_config, system_id, part_id, defect_category, action_taken) => {
    let currentDate = new Date();

    let data_body_kleberaupehmi = {
        "recording_time": currentDate.toISOString(),
        "defect_code": defect_category,
        "action_taken": action_taken
    };
    const res_kleberaupehmi = await sendRequest(`api/fuegen/${system_id.toString()}/${part_id.toString()}/kleberaupe_hmi`, data_body_kleberaupehmi, api_config.api_key);
    logResponse(res_kleberaupehmi);
    
    let data_body_kleberaupe = {
        "start_time": currentDate.toISOString(),
        "image_path": `image/${system_id.toString()}/${part_id.toString()}.png`
    };
    const res_kleberaupe = await sendRequest(`api/fuegen/${system_id.toString()}/${part_id.toString()}/kleberaupe`, data_body_kleberaupe, api_config.api_key);
    logResponse(res_kleberaupe);
}

const logResponse = (res) => {
    console.log(res);
    if (res.status === 202) {
        console.log('Request accepted');
    } else if (res.status === 403) {
        alert(`Forbidden access error with error code ${res.status}.`)
    } else if (res.status === 422) {
        alert(`Validation error with error code ${res.status}.`);
    } else if (res.status === 409){
        alert(`Entry with given id already exists in database. Conflict error with error code ${res.status}.`)
    } else {
        console.log(`Error with error code ${res.status}: ${res.text()}`);
    }
}

const sendRequest = async (address, data_body, api_key) => {
    const res = await fetch(address, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'X-API-KEY': api_key,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        },
        body: JSON.stringify(data_body),
    });
    return res;
}

export const requestImage = async () => {
    const res = await fetch('cam/getImage');
    const blob = await res.blob();
    const system_id = res.headers.get('X-system_id');
    const part_id = res.headers.get('X-part_id');
    const placeholder = res.headers.get('X-placeholder');
    return [URL.createObjectURL(blob), system_id, part_id, placeholder];
}

export const requestModelOutput = async (api_config, system_id, part_id, img_url) => {
//   try {
//     const formData = new FormData();
//     // formData.append("image_file", await fetch(filepath).then(response => response.blob()), filename);
//     formData.append("image_file", await fetch(img_url).then(r => r.blob()), `image/${system_id.toString()}/${part_id.toString()}.png`);

//     const response = await sendRequest(`https://gemeki-fuegen.aixbrain.de:443/fuegen/${system_id.toString()}/${part_id.toString()}/predict_ok_nok`, formData, api_config.api_key);

//     if (!response.ok) {
//       throw new Error("Network response was not ok");
//     }

//     const data = await response.json();
//     console.log("File uploaded successfully:", data);
//   } catch (error) {
//     console.error("Error uploading file:", error);
//   }
    const formData = new FormData();
    formData.append("image_file", await fetch(img_url).then(r => r.blob()), `image/${system_id.toString()}/${part_id.toString()}.png`);
    
    const res = await fetch(`api/fuegen/${system_id.toString()}/${part_id.toString()}/predict?binary=false`, {
        method: 'POST',
        headers: {
           // 'Content-type': 'image/png',
            'X-API-KEY': api_config.api_key,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'HEAD, GET, POST, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
        },
        body: formData,
    });
    
//    logResponse(res.json().then(data=>{return data}));
//    logResponse(res);
    return res.json().then(data => {return data});      //.then(r => {return r.json().then(data => {return data})})

}
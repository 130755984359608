<template>
    <div class="list">
        <div class="itembox" v-for="(entry, index) in this.list_entries" :key="index">
            <div class="textbox">
                {{ entry }}
            </div>
            <div class="delete-icon" @click="removeItem(index)">
                &#10006
            </div>
           
        </div>
    </div>
</template>

<script>
    export default {
        name: "ListRemoveable",
        props: {
            entries: {
                default: []
            }
        },
        methods: {
            removeItem(index) {
                this.list_entries.splice(index, 1)
            }
        },
        data() {
            return {
                list_entries: []
            }
        },
        created() {
            this.list_entries = this.entries  
        }
    }
</script>

<style scoped>
.itembox {
    background-color: white;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
}

</style>
<template>
    <button :style="{background: background_color, color: text_color}">
        <div class="img">
            <!-- <img class="engineer-img" src="../assets/icons/Icon_Werkender.svg"/> -->
            <svg :class="text_color" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 141.923 175.581">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rechteck_91" data-name="Rechteck 91" width="141.923" height="175.581" transform="translate(0 0)" fill="none" stroke="#000" stroke-width="8"/>
                </clipPath>
            </defs>
            <g id="Gruppe_330" data-name="Gruppe 330" clip-path="url(#clip-path)">
                <rect id="Rechteck_90" data-name="Rechteck 90" width="98.638" height="11.69" rx="1.117" transform="translate(21.643 46.557)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_113" data-name="Pfad 113" d="M38.562,32.649V9.706a7.2,7.2,0,0,1,7.206-7.2H57.35a7.2,7.2,0,0,1,7.2,7.2V32.649" transform="translate(19.403 1.258)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_114" data-name="Pfad 114" d="M93.229,38.75c0,27.222-9.139,49.29-34.486,49.29-25.721,0-34.487-22.068-34.487-49.29" transform="translate(12.205 19.498)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_115" data-name="Pfad 115" d="M72.824,38.75A11.5,11.5,0,0,1,84.577,49.97a11.5,11.5,0,0,1-11.753,11.22,12.209,12.209,0,0,1-4.283-.768" transform="translate(34.488 19.498)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_116" data-name="Pfad 116" d="M26.96,38.75A11.5,11.5,0,0,0,15.207,49.97,11.5,11.5,0,0,0,26.96,61.189a12.209,12.209,0,0,0,4.283-.768" transform="translate(7.652 19.498)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <line id="Linie_28" data-name="Linie 28" y2="13.605" transform="translate(51.234 100.814)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_117" data-name="Pfad 117" d="M70.171,99.274l-20.194-23.3H30.269c-9.877,0-27.769,8.013-27.769,31.869v25.746H70.171Z" transform="translate(1.258 38.23)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <line id="Linie_29" data-name="Linie 29" y2="13.605" transform="translate(90.688 100.814)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_118" data-name="Pfad 118" d="M47.462,99.274l19.344-23.3H86.513c9.879,0,27.769,8.013,27.769,31.869v25.746H47.645" transform="translate(23.881 38.23)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_119" data-name="Pfad 119" d="M55.628,7.688c17.325,4.64,29.9,18.555,29.9,35" transform="translate(27.99 3.868)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_120" data-name="Pfad 120" d="M18.894,42.657c0-16.336,12.415-30.178,29.564-34.908" transform="translate(9.507 3.899)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
            </g>
            </svg>
        </div>
        <div class="main-text">
            {{ maintext }}
        </div>
        <div class="sub-text">
            {{ subtext }}
        </div>
    </button>
</template>

<script>
    export default {
        name : 'AiFeedbackButton',
        props : {
            maintext: String,
            subtext: String,
            background_color: String,
            text_color: String
        }
    }
</script>

<style scoped>
    button{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;

        padding: 2%;
    }

    .engineer-img{
        height: 100%;
    }

    .img{
        height: 30%;
    }

    /* svg * {
        stroke: white;
    } */
    .black * {
        stroke: black;
    }

    .white * {
        stroke: white;
    }


</style>
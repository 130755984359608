<template>
  <img class="io_tick" src="../assets/img/IO_image2.png" alt="Kleberaupe IO">
  <BackButton
    text="Zurück zur Auswahl"
    color="white"
    image_name="Zurueck"
    target_name="ErrorDocumentation"
  ></BackButton>
</template>

<script>

import BackButton from "../components/BackButton.vue"
import { addEntry } from "../utils";
import ApiConfig from "../config/api_config.json"

export default {
  name: 'BeadIO',
  components: {
    BackButton
  },
  created() {
    this.$emit("set-title", "Kleberaupe io");
    this.$emit("set-titlecontainercolor", "#32DC80");
    document.body.style.backgroundColor = '#08D566';
    this.timeoutId = setTimeout( async() => {
      await this.addEntry();
      this.$router.push({ name: "ErrorDocumentation" });
    }, 3000);
  },
  methods : {
    async addEntry() {
      await addEntry(this.api_config, this.system_id, this.part_id, "", "");
    }
  },
  data() {
    return {
      api_config: ApiConfig
    }
  },
  props: {
    system_id: Number,
    part_id: Number
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.timeoutId);
    next();
  },
  emits: ["set-title", "set-titlecontainercolor"]
}
</script>

<style scoped>
.io_tick{
  width: 50%;
  height: 50%;
  object-fit: contain;
  display: block;
  margin-left: 25%;
  margin-top: 2.5%;
  margin-bottom: -10%;
}
</style>
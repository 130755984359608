<template>
  <div :class="bodytransparent" v-bind:class="getBackground()">
  <div class="content">
    <Header
      :title="title"
      :titleContainerColor="titleContainerColor"
    />
    <div class="wrapper">
      <BeadImage
        :imageUrl="beadImageUrl"
      />
      <router-view   
        @set-title="setTitle"
        @set-titlecontainercolor="setTitlecontainercolor"
        @request-image="requestImage"
        :system_id="system_id"
        :part_id="part_id"
        :placeholder="placeholder"
      />
    </div>
  </div>
  
  <Footer />
</div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import BeadImage from "./components/BeadImage.vue"
import { requestImage, requestModelOutput } from './utils'
import ApiConfig from "./config/api_config.json"

export default {
  name: 'App',
  updated() {
    console.log("hier bin ich:")
    console.log(this.$route.path)
    if(this.$route.path === '/UebersichtKIIOAbgelehnt'){
      document.body.style.backgroundImage = "linear-gradient(90deg, #00a300 50%, #ff6200 50%)" //'#00A300'
    }
    else if (this.$route.path === '/UebersichtKINIOAbgelehnt') {
      document.body.style.backgroundImage = "linear-gradient(90deg, #ff6200 50%, #00a300 50%)" //'#00A300'
    }
    else {
      document.body.style.backgroundImage = "linear-gradient(90deg, #00a30000 50%, #ff620000 50%)" //'#00A300' //'#00A300'
    }
  },
  components: {
    Header,
    Footer,
    BeadImage
  },
  data() {
    return {
      title: "Default",
      titleContainerColor: "black",
      beadImageUrl: "",
      system_id: -1,
      part_id: -1,
      placeholder: '1'
    }
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    setTitlecontainercolor(color) {
      this.titleContainerColor = color
    },
    getBackground () {
      console.log(this.$route.path)
      if(this.$route.path === '/UebersichtKIIOAbgelehnt'){
          return 'bodybackground';
      } else {
          return 'bodytransparent';
      }
    },
    async requestImage(){
      let [beadImageUrl, system_id, part_id, placeholder] = await requestImage();
      while(placeholder == '1'){
        this.beadImageUrl = beadImageUrl;
        await new Promise(r => setTimeout(r, 1000));
        [beadImageUrl, system_id, part_id, placeholder] = await requestImage();
      }
      this.beadImageUrl = beadImageUrl
      this.system_id = system_id
      this.part_id = part_id
      this.placeholder = placeholder
      
      let modelOutput = await requestModelOutput(ApiConfig, system_id, part_id, beadImageUrl);
      console.log(modelOutput);
      if (modelOutput.label.text_display == "iO") {
        this.$router.push({name:"AIBeadIO"});
      } else {
        this.$router.push({name:"AIBeadNIO", query: { faultType: modelOutput.label.defect_code }});
      }

    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

body {
  height: 100%;
  margin: 0;
}

html {
  min-height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.bodybackground {
  background-image: linear-gradient(90deg,
        #00a300 50%,
        #ff6200 50%);
}
.bodybackground1 {
  background-color: #f2ec36;
}
.bodytransparent {
  background-color: #00000000;
}

.content {
  padding-left: 40px;
  padding-right: 40px;
  min-height: 100%;
  padding-bottom: 100px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.wrapper {
  min-height: 100%;
  position: relative;
}

</style>

<template>
    <img src="../assets/icons/Fehlerbehebung.svg" class="troubleshooting_img" alt="Fehlerbehebung">
    <h1 class="img_text">Bitte Fehlerbehebung an Anlage durchführen</h1>
    <BackButton
      text="Nicht notwendig"
      color="#FF7C2B"
      image_name="nio"
      image_filter="invert(100%)"
      target_name="ErrorDocumentation"
      @navigate="this.addEntry"
    ></BackButton>
    <FinishButton
      text="Erledigt"
      :visible=true
      color="#08D566"
      image_name="io"
      image_filter="invert(100%)"
      target_name="OverviewMeasures"
      :target_parameters="{ chosenErrors: chosenErrors }"
    ></FinishButton>
</template>

<script>

import BackButton from "../components/BackButton.vue"
import FinishButton from "../components/FinishButton.vue"
import ApiConfig from "../config/api_config.json"
import TitlesErrors from "../assets/json/Beschreibungen_FehlerDokumentation.json"
import { addEntry } from "../utils";

export default {
    name: 'PromptTroubleshooting',
    components: {
        BackButton,
        FinishButton
    },
    props: {
        system_id: Number,
        part_id: Number
    },
    created() {
        this.$emit("set-title", "Übersicht Dokumentation Fehler")
        this.$emit("set-titlecontainercolor", "#FF7C2B")
        document.body.style.backgroundColor = '#DBDBDB'
        this.chosenErrors = this.$route.query.chosenErrors
        this.errors = this.chosenErrors.map(i => this.titlesErrors[i].title);
    },
    data() {
        return {
          chosenErrors: new Set([]),
          api_config: ApiConfig,
          titlesErrors: TitlesErrors,
        }
    },
    methods : {
        async addEntry() {
            await addEntry(this.api_config, this.system_id, this.part_id, JSON.stringify(this.errors), "");
            //this.$emit("request-image");
        }
    },
    emits:["set-title", "set-titlecontainercolor", "request-image"]
    }
</script>

<style scoped>

.troubleshooting_img{
    height: 50%;
    width: 20%;
    margin: auto;
    display: block;
    margin-top: 5%;
}
.img_text{
    text-align: center;
    margin-top: 4%;
    font-size: 28px;
}
</style>
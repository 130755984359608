<template>
    <MultiButtons
        description_color="#3404fc"
        :titles = titles
        @toggle-button="toggle_button"
        :initial = this.activeButtons
    />
    <BackButton
      text="Zurück"
      color="white"
      image_name="Zurueck"
      target_name="ErrorDocumentation"
      :target_parameters="{ chosenErrors: this.chosenErrors }"
    ></BackButton>
    <FinishButton
      text="Fertig"
      :visible=ready
      color="white"
      image_name="io"
      target_name="OverviewAll"
      :target_parameters="{ 
        chosenMeasures: Array.from(this.activeButtons) ,
        chosenErrors: chosenErrors
      }"
    ></FinishButton>
</template>

<script>

import MultiButtons from '../components/MultiButtons.vue'
import BackButton from "../components/BackButton.vue"
import Titles from "../assets/json/Beschreibungen_FehlerBehebung.json"
import FinishButton from "../components/FinishButton.vue"

export default {
    name: 'OverviewMeasures',
    props: {
    },
    components: {
        MultiButtons,
        BackButton,
        FinishButton
    },
    methods: {
      toggle_button(activeButtons){
        this.ready = (activeButtons.size != 0);
        this.activeButtons = activeButtons;
      }
    },
    created() {
        this.$emit("set-title", "Übersicht Dokumentation Fehler")
        this.$emit("set-titlecontainercolor", "#3404fc")
        document.body.style.backgroundColor = '#DBDBDB'
        console.log(this.$route.query.chosenErrors);
        this.chosenErrors = this.$route.query.chosenErrors;
    },
    data() {
        return {
            titles: Titles,
            ready: false,
            activeButtons: new Set([]),
            chosenErrors: new Set([])
        }
    },
    emits:["set-title", "set-titlecontainercolor"]
    }
</script>
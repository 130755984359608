import { createRouter, createWebHistory } from 'vue-router'
import ErrorDocumentation from '../views/ErrorDocumentation'
import BeadIO from '../views/BeadIO'
import BeadNIO from '../views/BeadNIO'
import ErrorCorrection from '../views/ErrorCorrection'
import OverviewDocumentation from '../views/OverviewDocumentation'
import OverviewChosenErrors from '../views/OverviewChosenErrors'
import PromptTroubleshooting from '../views/PromptTroubleshooting'
import OverviewMeasures from '../views/OverviewMeasures'
import OverviewAll from '../views/OverviewAll'
import AIBeadIO from '../views/AIBeadIO.vue'
import AIBeadNIO from '../views/AIBeadNIO.vue'
import AINoClassification from '../views/AINoClassification.vue'
import OverviewAIBeadNIO from '../views/OverviewAIBeadNIO.vue'
import OverviewAIBeadNIOReject from '../views/OverviewAIBeadNIOReject.vue'
import ReferenceImagesNIO from '../views/ReferenceImagesNIO.vue'
import OverviewAIBeadIOReject from '../views/OverviewAIBeadIOReject.vue'
import ReferenceImagesIO from '../views/ReferenceImagesIO.vue'

const routes = [
  {
    path: '/',
    redirect: {
        name: 'ErrorDocumentation'
    }
  },
  {
    path: '/KIKeineBeurteilung',
    name: 'AINoClassification',
    component: AINoClassification 
  },
  {
    path: '/KIKleberaupeIO',
    name: 'AIBeadIO',
    component: AIBeadIO
  },
  {
    path: '/KIKleberaupeNIO',
    name: 'AIBeadNIO',
    component: AIBeadNIO
  },
  {
    path: '/FehlerDokumentation',
    name: 'ErrorDocumentation',
    component: ErrorDocumentation
  },
  {
    path: '/KleberaupeIO',
    name: 'BeadIO',
    component: BeadIO
  },
  {
    path: '/KleberaupeNIO',
    name: 'BeadNIO',
    component: BeadNIO
  },
  {
    path: '/FehlerBehebung',
    name: 'ErrorCorrection',
    component: ErrorCorrection
  },
  {
    path: '/UebersichtDokumentation',
    name: 'OverviewDocumentation',
    component: OverviewDocumentation
  },
  {
    path: '/UebersichtGewaehlteFehler',
    name: 'OverviewChosenErrors',
    component: OverviewChosenErrors,
    props: true
  },
  {
    path: '/AufforderungFehlerbehebung',
    name: 'PromptTroubleshooting',
    component: PromptTroubleshooting
  },
  {
    path: '/UebersichtMassnahmen',
    name: 'OverviewMeasures',
    component: OverviewMeasures
  },
  {
    path: '/UebersichtGesamt',
    name: 'OverviewAll',
    component: OverviewAll
  },
  {
    path: '/UebersichtKINIO',
    name: 'OverviewAIBeadNIO',
    component: OverviewAIBeadNIO
  },
  {
    path: '/UebersichtKINIOAbgelehnt',
    name: 'OverviewAIBeadNIOReject',
    component: OverviewAIBeadNIOReject
  },
  {
    path: '/ReferenzbilderNIO',
    name: 'ReferenceImagesNIO',
    component: ReferenceImagesNIO
  },
  { path: '/UebersichtKIIOAbgelehnt',
    name: 'OverviewAIBeadIOReject',
    component: OverviewAIBeadIOReject
  },
  {
    path: '/ReferenzbilderIO',
    name: 'ReferenceImagesIO',
    component: ReferenceImagesIO
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

<template>
  <div class="fault-list">
      <ListRemoveable :entries="listEntries">
      </ListRemoveable>
  </div>
  <div class="wrapper_nio">
    
    <div class="wrapper_ai_feedback">
      <AiFeedbackButton @click="confirmErrors()" class="feedbackbutton" maintext="KI zustimmen" subtext="Korrekt erkannt" background_color="white" text_color="black">
      </AiFeedbackButton>
      
      <AiFeedbackButton @click="rejectErrors()" class="feedbackbutton" maintext="KI-Hinweise ablehnen" subtext="kein Fehler" background_color="black" text_color="white">
      </AiFeedbackButton>
    </div>
    <!-- <SingleButton 
        @click.stop="$router.push({
          name: 'OverviewDocumentation'
        })"
        style="margin-top: 20%"
        text="Dokumentation Fehler" 
        text_color="white" 
        background_color="black" >
    </SingleButton> -->
    <BackButton
      text="Zurück zur Auswahl"
      color="white"
      image_name="Zurueck"
      target_name="ErrorDocumentation"
    ></BackButton>
  </div>
</template>

<script>

import BackButton from "../components/BackButton.vue"
import SingleButton from "../components/SingleButton.vue"
import AiFeedbackButton from "../components/AiFeedbackButton.vue"
import ListRemoveable from "../components/ListRemoveable.vue"

export default {
  name: 'AIBeadNIO',
  props: {
  },
  data() {
    return {
      // Default entries
      listEntries: []
    };
  },
  components: {
    BackButton,
    SingleButton,
    AiFeedbackButton,
    ListRemoveable
  },
  created() {
    this.$emit("set-title", "Kleberaupe nio")
    this.$emit("set-titlecontainercolor", "#FF7C2B")
    document.body.style.backgroundColor = '#FF6200'
        
    // Check if 'faultType' was passed via query
    const faultType = this.$route.query.faultType;
    
    if (faultType) {
      // Add the passed 'faultType' to the list of entries
      this.listEntries.push(faultType);
    }
  },
  methods: {
    confirmErrors() {
      this.$router.push({
        name:'PromptTroubleshooting',
        query:{chosenErrors: [2,0,3]}
      })
    },
    rejectErrors() {
      this.$router.push({
        name:'OverviewAIBeadNIOReject',
      })
    }
  },
  emits:["set-title", "set-titlecontainercolor"]
}
</script>

<style scoped>
.wrapper_nio{
  display: flex;
}

.wrapper_ai_feedback{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.feedbackbutton{
  width: 49%;
  height: 40%;
}


</style>
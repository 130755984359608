<template>
    <div class="wrapper_back">
        <button :style="{ background: color }" @click="navigate(target_name, target_parameters)">
            <img :src="require(`../assets/icons/${image_name}.svg`)" :style="{ filter: image_filter }">
        </button>
        <h3 class="descriptor">{{text}}</h3>
    </div>
</template>
    
<script>
    
    export default {
        name: 'BackButton',
        props : {
            text: String,
            color: String,
            image_name: String,
            image_filter: {
                default: "",
                type: String
            },
            target_name: String,
            target_parameters : {
                default: {}
            }
        },
        methods: {
            navigate(target_name, target_parameters){
                this.$emit("navigate");
                this.$router.push({
                    name: target_name, 
                    query: target_parameters
                });
            }
        },
        emits: ["navigate"]
    }
</script>
  
<style scoped>
    button{
        display:block;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        border: none;
        background-color: white;
    }
    img{
        width: 35%;
        height: 30%
    }
    .descriptor{
        font-size: 12px;
        margin-left: 25px;
        margin-top: 7%;
        color: black;
    }
    .wrapper_back{
        display: inline-flex;
        width: 50%;
        position: fixed;
        bottom: 20px;
    }
</style>
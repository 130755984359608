<template>
  <div class="ai-output-container">
    <div> KI: </div>
    <div> Kein Fehler erkannt</div>
  </div>
  <div class="wrapper_nio">
    
    <div class="wrapper_ai_feedback">
      <AiFeedbackButton @click="confirm()" class="feedbackbutton" maintext="KI zustimmen" subtext="Korrekt erkannt" background_color="white" text_color="black">
      </AiFeedbackButton>
      
      <AiFeedbackButton @click="reject()" class="feedbackbutton" maintext="KI-Hinweise ablehnen" subtext="kein Fehler" background_color="black" text_color="white">
      </AiFeedbackButton>
    </div>
    <!-- <SingleButton 
        @click.stop="$router.push({
          name: 'OverviewDocumentation'
        })"
        style="margin-top: 20%"
        text="Dokumentation Fehler" 
        text_color="white" 
        background_color="black" >
    </SingleButton> -->
   
  </div>
</template>

<script>

import AiFeedbackButton from "../components/AiFeedbackButton.vue"

export default {
  name: 'AIBeadNIO',
  props: {
  },
  components: {
    AiFeedbackButton,
  },
  mounted() {
    this.socket = new WebSocket('ws://10.183.207.201:8080');
    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connected');
    });

    this.socket.addEventListener('onclose', (event) => {
      console.log('WebSocket closed: ', event);
    });

    this.socket.addEventListener('error', (event) => {
      console.log('WebSocket generic error: ', event);
    });

    this.socket.addEventListener('message', (event) => {
      const payload = JSON.parse(event.data);
      this.$router.push({name: 'BeadIO'});
      console.log("Websocket event listener")
      console.log(payload);
      if (payload.value === 0 ) {
          console.log('WebSocket change window to BeadIO');
          this.$router.push({name: 'BeadIO'});
      } else if (payload.value === 1 ) {
          this.$router.push({name: 'OverviewAIBeadIOReject'});
          console.log('WebSocket change window to OverviewAIBeadIOReject');
      }
    });
  },
  beforeUnmount() {
    this.socket.close();
  },
  created() {
    this.$emit("set-title", "Kleberaupe io")
    this.$emit("set-titlecontainercolor", "#38b434") //light green
    document.body.style.backgroundColor = '#00a300' //green
  },
  methods: {
    confirm() {
      this.$router.push({
        name:'BeadIO',
      })
    },
    reject() {
      this.$router.push({
        name:'OverviewAIBeadIOReject',
      })
    }
  },
  emits:["set-title", "set-titlecontainercolor"]
}
</script>

<style scoped>
.wrapper_nio{
  display: flex;
}

.wrapper_ai_feedback{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.feedbackbutton{
  width: 49%;
  height: 40%;
}

.ai-output-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  
}

.ai-output-container * {
  font-size: large;
  font-weight: bold;
}


</style>
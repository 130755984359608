<template>
  <img :src="image" @click="onClick()" class="imagebutton" />
</template>
  
  <script>
  export default {
    name: 'ImageButton',
    props : {
      image: {
        type: String,
        default: ""
      }
    },
    methods: {
      onClick() {
        this.$emit('btn-click')
      },
    },
  }
  </script>

<style scoped>
</style>
<template>
    <button
    :style="{ background: background_color, color: text_color}">
        {{text}}
    </button>
</template>

<script>
    export default {
        name: 'Button',
        props : {
            background_color: String,
            text_color: String,
            text: String
        }
    }
</script>
  
<style scoped>
    button{
        border: none;
        width: 100%;
        margin-top: 5%;
        border-radius: 7.5px;
        font-size: 20px;
        font-weight: bold;
        padding: 7.5%;
    }
</style>
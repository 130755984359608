<template>
    <DoubleList
        leftTitle="Übersicht Fehler"
        rightTitle="Übersicht Fehlerbehebung"
        :leftEntries="errors"
        :rightEntries="measures"
    />
    <div class="img_wrapper">
        <img src="../assets/icons/Danke.svg" class="thank_img" alt="Danke"/>
        <h1 class="img_text">Danke!</h1>
    </div>
    
</template>
  
<script>
  
  import TitlesMeasures from "../assets/json/Beschreibungen_FehlerBehebung.json"
  import TitlesErrors from "../assets/json/Beschreibungen_FehlerDokumentation.json"
  import DoubleList from "../components/DoubleList.vue"
  import ApiConfig from "../config/api_config.json"
  import { addEntry } from '../utils';

  export default {
    name: 'OverviewAll',
    components: {
      DoubleList
    },
    created() {
        this.$emit("set-title", "Übersicht")
        this.$emit("set-titlecontainercolor", "black")
        document.body.style.backgroundColor = '#DBDBDB'
        let chosenErrors = this.$route.query.chosenErrors;
        let chosenMeasures = this.$route.query.chosenMeasures;
        this.errors = chosenErrors.map(i => this.titlesErrors[i].title);
        this.measures = chosenMeasures.map(i => this.titlesMeasures[i].title);
        this.timeoutId =  setTimeout( async () => {
            await this.addEntry();
            this.$router.push({ name: "ErrorDocumentation" });
        }, 2000);
    },
    data() {
        return {
            titlesMeasures: TitlesMeasures,
            titlesErrors: TitlesErrors,
            api_config: ApiConfig,
        }
    },
    props: {
        system_id: Number,
        part_id: Number
    },
    methods : {
        async addEntry() {
            await addEntry(this.api_config, this.system_id, this.part_id, JSON.stringify(this.errors), JSON.stringify(this.measures));
        }
    },
    beforeRouteLeave (to, from, next) {
        clearTimeout(this.timeoutId);
        next();
    },
    emits:["set-title", "set-titlecontainercolor"]
  }
</script>
  
<style scoped>

    .thank_img{
        height: 50%;
        width: 12.5%;
        margin: auto;
        display: block;
        margin-top: 5%;
    }

    .img_text{
        text-align: center;
        margin-top: 2%;
        font-size: 22px;
    }

    .img_wrapper{
        align-items: center;
        justify-content: flex-end;
        position: fixed;
        bottom: 20px;
        margin-right: 40px;
        width: 85%;
    }

</style>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

async function prepareApp() {
  // run the mock server
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    process.env.NODE_ENV === 'production' // For WZL VM only. Remove this line for production
  ) {
    const { worker } = await require('./mocks/browser')
    return worker.start()
  }

  return Promise.resolve()
}

prepareApp().then(() => {
  createApp(App)
    .use(router)
    .mount('#app')
})

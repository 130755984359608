<template>
    <div class="container">
        <div class="ai icon">
            <svg id="Gruppe_257" data-name="Gruppe 257" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 162.41 175.581">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rechteck_73" data-name="Rechteck 73" width="162.41" height="175.581" fill="none" stroke="#000" stroke-width="8"/>
                </clipPath>
            </defs>
            <g id="Gruppe_257-2" data-name="Gruppe 257" transform="translate(0 0)" clip-path="url(#clip-path)">
                <path id="Pfad_62" data-name="Pfad 62" d="M23.878,78.425a23.464,23.464,0,0,1,3.971-46.589" transform="translate(2.138 15.511)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_63" data-name="Pfad 63" d="M20.163,31.836a23.367,23.367,0,0,1,14.658,5.141" transform="translate(9.824 15.511)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_64" data-name="Pfad 64" d="M39.719,17.025a17.729,17.729,0,0,1,35.167,3.2V147.586a23.059,23.059,0,1,1-46.119,0v-.143a17.948,17.948,0,0,1-2.258.143A17.728,17.728,0,0,1,8.784,129.858c0-4.67,4.759-12.082,4.759-12.082a17.735,17.735,0,0,1-1.767-32.011" transform="translate(1.218 1.218)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_65" data-name="Pfad 65" d="M22.054,41.7a17.728,17.728,0,1,1,27.3-22.579" transform="translate(8.523 5.898)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <ellipse id="Ellipse_20" data-name="Ellipse 20" cx="9.673" cy="9.673" rx="9.673" ry="9.673" transform="translate(109.658 10.479)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_69" data-name="Pfad 69" d="M50.638,43.317h15.5L75,13.55h9.984" transform="translate(24.672 6.602)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_70" data-name="Pfad 70" d="M103.354,36.1a9.672,9.672,0,1,1-9.673-9.673A9.673,9.673,0,0,1,103.354,36.1Z" transform="translate(40.932 12.877)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_71" data-name="Pfad 71" d="M113.04,62.769a9.672,9.672,0,1,1-9.673-9.673A9.673,9.673,0,0,1,113.04,62.769Z" transform="translate(45.651 25.87)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_72" data-name="Pfad 72" d="M50.638,53.967H77.055l6.681-21.034h16.534" transform="translate(24.672 16.046)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <ellipse id="Ellipse_21" data-name="Ellipse 21" cx="9.673" cy="9.673" rx="9.673" ry="9.673" transform="translate(109.658 146.726)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_73" data-name="Pfad 73" d="M50.638,85.147h15.5L75,114.914h9.984" transform="translate(24.672 41.486)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_74" data-name="Pfad 74" d="M103.354,88.948a9.672,9.672,0,1,0-9.673,9.673A9.673,9.673,0,0,0,103.354,88.948Z" transform="translate(40.932 38.625)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_75" data-name="Pfad 75" d="M50.638,71.636H77.055L83.735,92.67h16.534" transform="translate(24.672 34.903)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <line id="Linie_18" data-name="Linie 18" x1="63.241" transform="translate(76.105 88.638)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
            </g>
            </svg>           
        </div>
        <div class="plus"> + </div>
        <div class="worker icon">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 141.923 175.581">
            <defs>
                <clipPath id="clip-path">
                <rect id="Rechteck_91" data-name="Rechteck 91" width="141.923" height="175.581" transform="translate(0 0)" fill="none" stroke="#000" stroke-width="8"/>
                </clipPath>
            </defs>
            <g id="Gruppe_330" data-name="Gruppe 330" clip-path="url(#clip-path)">
                <rect id="Rechteck_90" data-name="Rechteck 90" width="98.638" height="11.69" rx="1.117" transform="translate(21.643 46.557)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_113" data-name="Pfad 113" d="M38.562,32.649V9.706a7.2,7.2,0,0,1,7.206-7.2H57.35a7.2,7.2,0,0,1,7.2,7.2V32.649" transform="translate(19.403 1.258)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_114" data-name="Pfad 114" d="M93.229,38.75c0,27.222-9.139,49.29-34.486,49.29-25.721,0-34.487-22.068-34.487-49.29" transform="translate(12.205 19.498)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_115" data-name="Pfad 115" d="M72.824,38.75A11.5,11.5,0,0,1,84.577,49.97a11.5,11.5,0,0,1-11.753,11.22,12.209,12.209,0,0,1-4.283-.768" transform="translate(34.488 19.498)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_116" data-name="Pfad 116" d="M26.96,38.75A11.5,11.5,0,0,0,15.207,49.97,11.5,11.5,0,0,0,26.96,61.189a12.209,12.209,0,0,0,4.283-.768" transform="translate(7.652 19.498)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <line id="Linie_28" data-name="Linie 28" y2="13.605" transform="translate(51.234 100.814)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_117" data-name="Pfad 117" d="M70.171,99.274l-20.194-23.3H30.269c-9.877,0-27.769,8.013-27.769,31.869v25.746H70.171Z" transform="translate(1.258 38.23)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <line id="Linie_29" data-name="Linie 29" y2="13.605" transform="translate(90.688 100.814)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_118" data-name="Pfad 118" d="M47.462,99.274l19.344-23.3H86.513c9.879,0,27.769,8.013,27.769,31.869v25.746H47.645" transform="translate(23.881 38.23)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_119" data-name="Pfad 119" d="M55.628,7.688c17.325,4.64,29.9,18.555,29.9,35" transform="translate(27.99 3.868)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
                <path id="Pfad_120" data-name="Pfad 120" d="M18.894,42.657c0-16.336,12.415-30.178,29.564-34.908" transform="translate(9.507 3.899)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="8"/>
            </g>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'HumanAiIcon',
        props : {
            
        }
    }
</script>

<style scoped>
.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px;
    width: 150px;
    height: 50px;
}

.icon {
    width: 30px;
    height: 30px;
}

</style>
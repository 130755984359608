<template>
  <div class="wrapper_nio">
    <SingleButton 
        @click.stop="$router.push({
          name: 'OverviewDocumentation'
        })"
        style="margin-top: 20%"
        text="Dokumentation Fehler" 
        text_color="white" 
        background_color="black" >
    </SingleButton>
    <BackButton
      text="Zurück zur Auswahl"
      color="white"
      image_name="Zurueck"
      target_name="ErrorDocumentation"
    ></BackButton>
  </div>
</template>

<script>

import BackButton from "../components/BackButton.vue"
import SingleButton from "../components/SingleButton.vue"
import AiFeedbackButton from "../components/AiFeedbackButton.vue"

export default {
  name: 'BeadNIO',
  props: {
  },
  components: {
    BackButton,
    SingleButton,
    AiFeedbackButton
  },
  created() {
    this.$emit("set-title", "Kleberaupe nio")
    this.$emit("set-titlecontainercolor", "#FF7C2B")
    document.body.style.backgroundColor = '#FF6200'
  },
  emits:["set-title", "set-titlecontainercolor"]
}
</script>

<style scoped>
.wrapper_nio{
  display: flex;
}

</style>